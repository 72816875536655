html, body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  height: 100%;
}

#root {
  /* position: absolute; */
  /*top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  height: 100%;
}


.container-fluid{
  height: 100%;
  display: grid;

  /* border: solid 1px blue; */
}

.hero-bg-lightblue{
  /* height: 400px; */
  /* height: 100vw; */
  height: 45%;
  /* max-width: 100%; */
  /* background-color: #f9f8fb; */
  background-color: #f0f5ff;
}

.container{
  display: grid;
  gap: 2rem;
  /* /* min-height: 100%; */
  height: 100%; 

}


.navbar {
  display: flex;
  height: 70px;

}

/* .navbar img{
  object-fit: contain; 
  height: 100%;
} */

/* .navbar a{
  font-size: 1.5rem;
} */

/* .nav-brand{
  margin-right: auto;
} */

.navbar-brand{
  /* height: 90px; */
  background-size :contain;
  background-repeat: no-repeat;
}

.navbar-brand img{
  object-fit: contain; 
  height: 40px; 
}

/* .nav-icon{ */
.navbar-icon{
  display: flex;
  align-items: center;
  /* font-size: 1.5rem; */
  font-size: 2rem;
  font-weight: 900;
  border: #F6B21B solid 2px;
  border-radius: 20%;
  padding: 5px;
  /* height: 45px; */
  height: 40px;

}
.navbar-icon svg{
  color: #314B66 ;
  /* color: #F6B21B ; */
  /* color: #f9a825; */

}

.nav-icon:hover{
  /* font-size: 1.75rem; */
  color: #b5179e;
}

.hero{
  display: grid;
}

.hero-title{
  justify-self: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 200;
  padding: 0;
  /* padding: 0 20vw; */
  line-height: 2.25rem;
  margin-top: 2rem;
}

.hero-subtitle{
  justify-self: center;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 300;
  /* padding: 0 20vw; */
  padding: 0 5%;
  line-height: 1rem;
  margin-top: 1rem;
}

.hero-body{
  display: grid;
  justify-self: center;
  text-align: center;
  margin-top: 2rem;
}

.hero button{
  margin-top: 1rem;
  width: 225px;
  justify-self: center;
  /* background-color: #b5179e; */
  background-color: #314B66;
  /* color: #314B66; */
}

.hero-img{
  object-fit: cover; 
  width: 80vw; 

}

.hero-img img{
  /* height: 100%; */
  width: 100%;
}


/* .container-lower{
  margin-right: 7.5%;
  margin-left: 7.5%;
  display: grid;

} */

/* .main{
  display: grid;
  gap: 2rem;
}

.main-image{
  height: 290px;
  width: 100%;
} */

.features{
  display: flex;
  gap: 1.5rem;
  overflow-y: scroll;
  height:150px;
}

.features-icon{
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.features-card {
  /* font-size: 0.75rem; */
  /* font-size: 1rem; */
  font-size: 0.8rem;
  height: 150px;
  min-width: 90%;

  /* background-color: #f0f5ff; */
  /* background-color: #fff0f680; */
  background: rgba(255, 240, 246, 0.4);
  /* background-color:  rgba(240, 245, 255, 0.4); */
  padding: 1rem;
  /* box-shadow: 0px 1px #b5179e; */
  border-left: 1px solid #b5179e;

}

.features-card-title {
  /* font-size: 0.8rem; */
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.features-card-body {
  /* padding: 1rem; */
  font-weight: 200;
  line-height: 0.9rem;
}

.cta{
  height: 175px;
  background-color: #1769aa;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* justify-content: center; */
  /* margin-top: 2rem; */
  align-items: center;
  /* justify-content: space-around; */
  justify-content: center;
  /* justify-self: center; */
  /* width: 100%; */
}

.cta-text{
  /* font-family: 'Poppins', sans-serif; */
  /* width: 70%; */
  /* width: 100%; */
  /* margin: 1rem; */

  color: white;
  font-size: 0.8rem;
  text-align: center;
  padding: 0 1rem;
  /* margin: 1rem; */
  /* font-weight: 500; */
  /* margin-left: 5%; */
}
.cta-button{
  /* align-self: center; */
  color: white;
  display: flex;
  /* margin-bottom: 1rem; */
  /* justify-content: center; */
  /* justify-items: center; */
  /* justify-self: center; */
  /* align-items: ; */
}

.cta-button button{
  color: white;
  border-color: white;
}

.landing-form-container{
  /* margin: 0 7.5%; */
  /* height: 500px;
  padding-top: 2rem;*/
  /* padding-bottom: 10%;  */
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.landing-form-bg-purple{
  /* Duplicate (to replace) of auth-img */
  display:none; 
  align-items: center;
  justify-items: center;
  background: #702670;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: center;
}

.landing-form {
  /* grid-column: 6 / -1; */
  grid-column: 1/-1;
  background: #f9f8fb;
  border-radius: 12pt;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.landing-form form{
  padding: 0 2rem;
}

.landing-form .custom-input, .custom-formcontrol {
  margin-top: 1.5rem;
}

.landing-form .custom-iconbutton {
  margin-top: 2rem;
}

/* .landing-form .custom-formcontrol .custom-input{
  margin-top: 0;
} */

.landing-form .custom-input label,
.landing-form .custom-input input,
.landing-form .custom-input span,
.landing-form .custom-select,
.landing-form .custom-radio span {

  font-size: 0.9rem;
}



/* .landing-form .custom-outlinedinput{
  display: flex;
  align-content: center;
}

.landing-form .custom-endoadornment{
  display: flex;
  align-content: center;
  align-items: center;
} */






.landing-form form button{
  margin-top: 1rem;
  margin-bottom: 2rem;

}

.container-auth {
  margin: 0 7.5%;
  height: 500px;
  padding-top: 2rem;
  padding-bottom: 10%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-columns: 1fr 1fr; */
}

.auth-img {
  /*Hidden on Mobile */
  display:none; 
  align-items: center;
  justify-items: center;
  background: #702670;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: center;
}

.auth-img-title{
  color: white;
  font-size: 2rem;
  font-weight: 200;
  /* justify-self: center; */
}

.auth-img-subtitle{
  color: white;
  font-size: 0.75rem;
  padding: 0 0.5rem;
  /* font-weight: 200; */
}

.auth-form {
  /* grid-column: 1/-1;
  background: #f9f8fb;
  border-radius: 12pt;
  display: flex;
  flex-direction: column;
  justify-content: center;  */
  /* padding-left: 10rem;
  border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */


}

.auth-form form{
  /* padding: 0 2rem; */
  padding: 0 15%;
  margin-top: 3rem;

  /* padding-left: 10rem; */

}

.auth-form-brand{
  /* height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form-brand img{
  height: 60%;
  margin-bottom: 1.5rem;

}

.auth-form-avatar-container{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center; 
}

.auth-form-avatar{
  /* justify-self: center;
  align-self: center; */
}

/* .auth-form button{ */
.auth-form-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.auth-form-button button{
  width:100%
}



.container-contact {
  /*height: 100%;*/
  height: 100vh;
  display: grid;
  /*margin-left: 7.5%;
  margin-right: 7.5%;*/
  grid-template-columns: repeat(12, 1fr);
  /*grid-template-rows: 50px auto auto auto;*/
  /* border: 1px solid red;  */
}

.contact-img {
  /* display: grid; */
  grid-column: 1 / 4;
  align-items: center;
  justify-items: center;
  margin-top: 1rem; 
  /*background: #702963;
  background: #43294D;*/
  background: #702670;
  /* height: 100%; */
  /*min-height: 100%;*/
}

.contact-form {
  grid-column: 4 / -1;
  background: #f9f8fb;
  border-radius: 12pt;
  padding: 1rem 5rem;
  /* padding: 1rem; */
  margin-top: 1rem;
}

/* .contact-form label{
  font-size: 0.9rem;
  padding-left: 0;
} */


.contact-form .form-floating label{
  font-size: 0.9rem;
  padding-left: 0;
}

.contact-form input, .contact-form textarea{
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  background-color: #f9f8fb;
  /* padding-left: 12px !important;  */
  font-size: 0.9rem;
  /*margin-bottom: 1rem;*/
  margin-top: 1rem;
  /* margin-left: 10px;  */
}

.contact-form .form-floating input, .contact-form .form-floating textarea{
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  background-color: #f9f8fb;
  padding-left: 3px;
  font-size: 0.9rem;
  /*margin-bottom: 1rem;*/
  margin-top: 1rem;
  /* margin-left: 10px;  */
}

.contact-form .form-floating textarea {
  height: 100px !important;
}

.contact-form button{
  margin-top: 1rem;
}

.dashboard-container{
  display: grid;
  /* grid-template-columns: minmax(200px, 17.5%) auto; */
  grid-template-columns: 1/-1;

  height: 100%;
  /* border: 1px solid red; */
}

.dashboard-menu{
  /* Hidden on Mobile */
  /* display: none;  */

  /* display: flex; */
  flex-direction: column;
  /* grid-column: 1/2; */
  align-self: stretch;
  margin-left: 10%;
  margin-top: 2%;
  

  /* height: 100vh; */
  border-right: solid;
  border-right-width: thin;
  border-right-color: #d6deea;
}

/* .nav-link{
  text-transform: uppercase;
  /* color: #95aac9 !important; */
  /* font-size:.65rem;
  font-weight: 500;
} */ 

#sidebar-collapse-acc, #sidebar-collapse-crm{ 
  /* border: 1px solid red !important; */
  margin-bottom: 0.5rem;
}

.collapse-nav-link{
  margin-left: 1rem;
  font-size: 0.8rem;
  line-height: 0.5rem;
  /* border: 1px solid red; */
}

.collapse-nav-link a:last-child{
  border: 1px solid red !important;
}

.dashboard-brand{
  /* grid-column: 1/-1;
  font-size: 1rem;
  font-weight: 600;
  color: #023e8a; */
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.dashboard-brand img{
  object-fit: contain; 
  /* height: 40%; */
  /* height: 40%; */
  height: 40px; 


  /* margin-top: 0.75rem; */
}  

.dashboard-navlink{
  /* border: 1px solid red; */
  /* font-size: 0.8rem; */
  /* padding: 0; */
  padding-left: 0;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.dashboard-navlink span{
  padding: 0;
  margin: 0;
  font-size: 0.775rem;
  color: #607d8b;
  /* color: #002984; */
  /* color: #1976d2; */
  /* color: #3f51b5; */
  font-weight: 500;
  /* line-height: 0.25rem; */
  font-family: 'Poppins', sans-serif;
}

.dashboard-collapse{
  /* margin-bottom: 4rem; */
  padding-bottom: 0.5rem;

}

.dashboard-submenu-navlink{
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 1.6rem;
}

.dashboard-submenu-navlink span{
  font-weight: 300;
  font-size: 0.75rem;
  /* color: #0d47a1; */
  /* line-height: 0.1rem; */
  /* margin-bottom: 0.25rem; */
}

.dashboard-navlink svg{
  font-size: 1.2rem;
  /* font-weight: 500; */

}

.dashboard-navlink-text{
  margin: 0;
}

.dashboard-navicon{
  /* color: #90127e; */
  /* color: #2196f3; */
  color: #2196f3;
  font-size: 2rem;
  margin-right: 5px;
  /* min-width: none; */
}


.dashboard-navbar li .nav-link {
  color: #1976d2;
  font-weight: 500;
}

.dashboard-navbar li .nav-link svg {
  color: #90127e;

}

.dashboard-navbar li a:hover{
  color: #b5179e;
  font-weight: 500;
  font-size: .85rem;
}

.dashboard-main{
  background: #edf3f8;
  height: 100%;
  /* overflow-y: auto; */
  overflow: auto;
  /* border: solid 1px red; */
  /* display: flex;
  flex-direction: column; */

}

.dashboard-content{
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    /* background: #edf3f8; */
}

.dashboard-alert{
  /* margin-top: 1rem; */
}

.dashboard-drawer{
  width: 250px;

}

.dashboard-content-header{
  margin-top: 1rem;
 
  /* MOVED TO DASHBOARD DIVIDER
  border-bottom: solid;
  border-bottom-width: thin;
  border-bottom-color: #d6deea; */
}

.dasboard-divider, .css-9mgopn-MuiDivider-root{
  border-color: grey;
}

.dashboard-content-pretitle{
  text-transform: uppercase;
  color: #95aac9;
  /* font-size:.625rem */
  font-size:.65rem;
  font-weight: 500;
  
  /* font-size:.7rem */
}

.dashboard-content-title{
  /*font-size:1.625rem;*/
  font-size:1.4rem;
  margin-bottom: 2%;
}

.dashboard-content-subheader{
  /* background-color: #f9fbfd;
  border: 0;
  padding: 1rem; */
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

#dashboard-landing{
  flex-wrap: wrap;

}

.dashboard-content-subheader .dashboard-card {
  background-color: #f9fbfd;
  border: 0;
  padding: 1rem;
  width: 100%;
}

.dashboard-card-pretitle{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
}

.dashboard-card-text{
  font-size:1.5rem;
  font-weight: 300;
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */

}

/* .dashboard-content .dashboard-mobile-navbar{
  display:flex;
  align-items: center;
}

.dashboard-content .dashboard-mobile-navbar .dashboard-icon{
  margin-left:auto;
} */

.dashboard-content .nav-tabs {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;

  /* z-index: 1000; */ 
}

.dashboard-content .fab {
  position: fixed; 
  bottom: 4%;
  right: 1.9%;
  /* z-index: 1000; */ 
}

.dashboard-content .fab:hover {
  background-color: #b5179e ;
}

.dashboard-content .dashboard-card{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#settings-card{
  margin-top: 2rem;
  /* margin-bottom: 0.5rem; */
}

.dashboard-content .dashboard-card .dashboard-card-header{
  /* padding: 0.5rem; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dashboard-content .dashboard-card .dashboard-card-content{
  /* padding: 0.5rem; */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.dashboard-content .dashboard-card .dashboard-card-title{
  font-size:1.5rem;
  font-weight: 200;
  /* padding-left: 0.75rem;
  padding-bottom: 0.75rem; */

}

.dashboard-content .dashboard-card .dashboard-card-subtitle{
  text-transform: uppercase;
  /* color: #95aac9; */
  color: #607d8b;
  font-size:0.65rem;
  /* font-weight: 500; */
  font-weight: 500;

  padding-bottom: 0.75rem; 

}

.dashboard-content .dashboard-card p{
  padding: 0;
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
}

.dashboard-content .custom-list-icon{
  font-size: 1.75rem;
  color: #1769aa;
  min-width: 0;
  padding-right: 7.5px;

}
/*Responsive table design #MobileFirst */
/* .styled-table thead{
  display: none;
} 

.styled-table, 
.styled-table tbody, 
.styled-table tr,
.styled-table td{
  display: block; 
  width: 100%
}*/


.dashboard-report-header {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.dashboard-report-title {
  flex: 80%;
  margin-bottom: 10px;

}

.dashboard-report-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  /* margin-bottom: 10px;
  margin-bottom: 2%; */
  /* border-bottom: solid 1px; */

}
.dashboard-report-menu-item {
  margin-left: 0.15rem;
}

.styled-table {
  table-layout:auto;
  margin-top: 1rem;
  font-size: 0.8rem;
  background-color: #f9fbfd;
  border-radius:10px;

  /*Testing Tabs */
  width: 100%;

  /* padding: 0 30px; */
  /* border-spacing: 1rem 0; */
}

.styled-table th{
  text-transform: uppercase;
  color: #95aac9;
  font-size:.65rem;
  font-weight: 500;
}

.styled-table td:first-child, .styled-table th:first-child {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}​ 

.styled-table td, .styled-table th{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styled-table tr {
  border-bottom: 1pt solid #d6deea;

  border-bottom-width: thin;
  padding-left: 1rem;

  /* border: 1px solid red; */

}

.styled-table tbody tr:hover {
  background-color: #f4eee3;
}


.styled-table-menu{
  font-size: 1rem;
  padding-right: 5px;
}

.styled-table-menu :hover{
  font-size: 1.25rem;
  color: #b5179e;
}

.styled-threedots-dropdown{
  font-size: 0.8rem;

}

.container-dashform{
  gap: 0;
  /* border: 1px solid red; */
  /* align-items: flex-start; */

  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  /* display: grid; */
  /* height: 100%; */

}

.dashboard-form{
  display: grid;
  /* margin-left: 10%;
  margin-right: 10%; */
  padding: 1.5rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
}

.dashboard-form-light{
  background-color: #F9FBFD;
  margin-top: 1rem;
  padding: 1rem;
}
/* .dashboard-main form {
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; 
} */

/* .dashboard-main form button{ */


.dashboard-form .custom-input, .dashboard-form .ant-picker {
  margin-top: 1.5rem;
  /* font-size: 0.5rem; */
}

.dashboard-form .custom-input label,
.dashboard-form .custom-input input,
.dashboard-form .custom-input span,
.dashboard-form .custom-select {
  font-size: 0.9rem;
}


.custom-radio span {
  font-size: 0.9rem;
  /* padding: 1px; */
  padding-right: 1px;
  /* padding-top: 0; */
}

.custom-tab-container{
  /* text-transform: none; */
  border-bottom: 1px solid lightgray;
}

.custom-tab{
  text-transform: none;
}

.custom-tab-panel{
  padding-left: 0;
  padding-right: 0;
}

/* MUI select list */
.MuiMenu-list li {
  font-size: 0.9rem;
}


.cust-auto-complete {
  display: flex;
  align-items: center;
  align-content: center;
  /* border:1px solid blue; */

}

/* Correct bug in misaligned dropdown button -> https://stackoverflow.com/a/70082097  */

.custom-input .MuiAutocomplete-endAdornment{
  margin-top: -16px;
}

.custom-input .MuiAutocomplete-endAdornment svg{
  color: #2196f3;
}



/* Ant Design Responsive Date Range Picker */
.dashboard-form .ant-picker{
  font-size: 2rem;
  height: 1.4375em;
  /* width: 50%; */
  width: 100%;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #edf3f8;
  ;
}

.dashboard-form .ant-picker span{
  /* color: #9c27b0; */
  /* color: #F6B21B; */

}

.ant-picker-panels { 
  flex-direction: column;
} 

.dashboard-form .custom-upload-card .ant-upload{
  /* border: 1px solid red */
  background-color: #F8FAFC;
}

.dashboard-form button{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dashboard-form .button-border-top{
  width: 100%;
  border-top: thin solid #d6deea;
  margin-top: 1rem;
}

.custom-pill-chip{
  height: 18px;
  /* border: 1px solid red; */
}
.custom-pill-chip span{
  font-size: 0.65rem;
}





.newtxn-form {
  /* margin-top: 1rem; */
}

.newtxn-form label {
  font-size: 0.9rem;
  margin-top: 1.5rem;
}

.newtxn-form input, .newtxn-form select{
  font-size: 0.9rem;
  line-height: 2rem;
}

.newtxn-form button{
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.custom-pagination{
  /* margin-left: 5%;
  margin-right: 5%; */
  margin-top: 1rem;
  display: flex;
  align-items: center;

  justify-content: space-between;
  /* justify-content: center; */

  background-color: #f9fbfd;
  height: 60px;
}

.custom-mui-pagination{
  /* margin-left: 5%;
  margin-right: 5%; */
  margin-top: 1rem;
  display: flex;
  align-items: center;

  /* justify-content: space-between; */
  justify-content: center;

  background-color: #f9fbfd;
  height: 60px;
}


.custom-mui-pagination ul li button{
  /* background-color: #fb8c00; */
  /* color: white; */
  /* border: solid red 1px; */
  font-size: 0.75rem;
  /* font-weight: 600; */
}

.custom-mui-pagination ul li button:focus {
  border: #fb8c00 solid 2px;
  font-weight: 600;
  font-size: 0.75rem;
  background-color: #f9fbfd;

  /* background-color: #F6B21B */
  /* background-color: #fb8c00; */
  /* color: white; */
}
.custom-mui-pagination ul li button:hover {
  /* border: #fb8c00 solid 2px; */
  /* border: red solid 1px; */
}


.custom-pagination-pageno{
  display: flex;
  background-color: #f9fbfd;
  /* align-items: center; */
  /* align-items: stretch; */
  /* border: 1px solid red; */
}

.custom-pagination-pageno .page-item .page-link  {
  background-color: #f9fbfd;
  border: 0;
  /* border: 1px solid red; */
}

/* .custom-pagination-next {
  width: 10vw;
  display: flex;
  justify-content: center;
  border-left: 1px solid #dee2e6;
} */



.custom-pagination-next .page-link, .custom-pagination-prev .page-link {
  background-color: #f9fbfd;
  border: 0;
  width: 10vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border-left: 1px solid #dee2e6; */
  /* border: 1px solid red; */
}
.custom-pagination-prev .page-link{
  border-right: 1px solid #dee2e6;
}

.custom-pagination-next .page-link{
  border-left: 1px solid #dee2e6;
}


/* .custom-pagination-pageno .page-link{
  height: 100%; 
  align-self: stretch;
} */

.custom-pagination-pageno .page-item .page-link{
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* align-self: stretch; */
}


/* .custom-pagination-next:hover,   
.custom-pagination-next .page-link:hover, */
.custom-pagination-pageno .page-item .page-link:hover
{
  color: white;
  background-color: #b5179e;
}

.custom-pagination-prev .page-link:hover,
.custom-pagination-next .page-link:hover
{
  border-bottom: 2px solid #b5179e;
} 


.footer-bg-lightblue{
  /* height: 400px; */
  /* height: 100vw; */
  /* max-width: 100%; */
  /* background-color: #f9f8fb; */
  /* min-height: 100%; */
  background-color: #f0f5ff;
  display: flex;
  justify-content: center;
  /* align-items: end; */
  align-items: flex-end;
  align-self: flex-end;
  /* border: 1px solid red; */
  height: 75px;
}

.footer-bg-lightblue .footer{
  /* margin-top: 1rem; */
  padding: 1rem 0;
}

.footer-bg-lightblue .footer .footer-logo{
  /* font-size: 1.75rem; */
  display: flex;
  /* gap: 5px; */
}

.footer-bg-lightblue .footer .footer-logo svg{
  font-size: 2rem;
  display: flex;
  /* gap: 5px; */

}

.footer-bg-lightblue .footer .footer-logo a:hover{
  color: #b5179e;
}


.footer{
  display: flex; 
  gap: 20px;
  justify-content: space-between;
  /* padding-right: 10vw; */
  
}


.footer-logo{
  font-size: 1.5rem;
  display: flex;
  gap: 3px;
  /* margin-right: 10vw; */
}

.footer-section-title{
  font-size: 0.75rem;
  font-weight: 500;
}

.footer-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* line-height: 1rem; */
  line-height: 1.15rem;

}

/* .footer-list>li>a, .footer-list a{ */

.footer-list a{
  text-decoration: none;
  color: #2E5A81;
  font-size: 0.7rem;
}

/* .footer-list>li>a:hover{ */
.footer-list a:hover{
  color: #b5179e;
  font-weight: 500;
}

.footer-list li{
  color: #2E5A81;
  font-size: 0.7rem;
  /* line-height: 1.2rem; */

}

.footer-list li svg{
  padding-right: 2pt;
}

.footer-services{
  font-size: 0.7rem;
  /*padding-top: 5px; */

  /* background-color: grey; */
}

.footer-section{
  /* border:1px solid red */
}

.footer-section-company{
  /* Hidden on Mobile */
  display: none; 
}

.footer-section-legal{
  /* Hidden on Mobile */
  display: none; 
}


.footer-copyright{
  font-size: 0.7rem;
  margin-top: 5px;
}





/*Responsive design for Tablets*/
@media screen and (min-width: 768px) and (max-width: 1024px) {

.navbar-brand img{
  /* object-fit: contain;  */
  height: 100%;
}
    

.hero-bg-lightblue{
  height: 45vw;
}

.hero-title{
  font-size: 2.95rem;
  /* padding: 0 5rem; */
  padding: 0 10%;

}

.hero-subtitle{
  /* padding: 0 20vw; */
  padding: 0 10%;
}

.hero-img{
  width: 70vw; 
}

.features-card {
  min-width: 50%;
  font-size: 0.85rem;
    /* height: 150px; */
    /* background: rgba(255, 240, 246, 0.4); */
  padding: 1rem;
    /* border-left: 1px solid #b5179e; */
}

.features-card-title {
    font-size: 0.85rem;
    /* font-weight: 600;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem; */
}

.cta{
  height: 100px;
  flex-direction: row;
  justify-content: space-around;
}
.cta-text{
  /* font-family: 'Poppins', sans-serif; */
  width: 70%;
  color: white;
  font-size: 0.8rem;
  text-align: left;
  padding: 0;

  /* font-weight: 500; */
  /* margin-left: 5%; */
}

/* .auth-form{
  grid-column: 6 / -1;
}  */

.auth-form form{
  padding: 0 25%;
  /* padding: 0 15%;
  margin-top: 3rem; */

}

.landing-form{
  grid-column: 6 / -1;
}

.auth-img{
  display: flex;
  grid-column: 1 / 6;

}

.landing-form-bg-purple{
  display: flex;
  grid-column: 1 / 6;

}


.footer-section-company{
  display: block; 
}

.footer-section-legal{
  display: block; 
}

}





/*Responsive design for Desktops and higher*/
@media screen and (min-width: 1025px ) {

.navbar-brand img{
  /* object-fit: contain;  */
  height: 100%;
  /* border: red solid 1px; */
}

.navbar-icon{
  height: 45px;
}


.hero-bg-lightblue{
  height: 35vw;
}

.hero-title{
  font-size: 3rem;
  line-height: 3rem;
  /* padding: 0 5rem; */
  padding: 0 15%;
}

.hero-subtitle{
  /* padding: 0 20vw; */
  padding: 0 15%;
}

.hero-img{
  width: 60vw; 
}

.features{
  /* margin-top: 2rem; */
  display: flex;
  /* gap: 1.5rem; */
}


.features-card {
  min-width: 0;
  font-size: 0.75rem;
  /* height: 150px; */
  /* background: rgba(255, 240, 246, 0.4); */
  padding: 1rem;
  /* border-left: 1px solid #b5179e; */
}

.features-card-title {
  font-size: 0.75rem;

}

.cta{
  height: 100px;
  /* background-color: #1769aa; */
  /* display: flex; */
  flex-direction: row;
  gap: 0;
  /* align-items: center; */
  justify-content: space-around;
}

.cta-text{
  /* font-family: 'Poppins', sans-serif; */
  width: 70%;
  color: white;
  font-size: 0.8rem;
  text-align: left;
  padding: 0;

  /* font-weight: 500; */
  /* margin-left: 5%; */
}

.container-auth{
  visibility: visible;

}

/* .auth-form{
  grid-column: 7 / -1;
} */

.auth-form form{
  padding: 0 35%;
}

.landing-form{
  grid-column: 7 / -1;
}

.auth-img{
  display: flex;
  grid-column: 1 / 7;

}

.landing-form-bg-purple{
  display: flex;
  grid-column: 1 / 7;

}

/* #dashboard-landing{
  flex-wrap: wrap;
} */
.dashboard-container{
  grid-template-columns: minmax(200px, 17.5%) auto;

}

/* .dashboard-menu{
  display: flex;

} */


#dashboard-landing .dashboard-card{
  /* Limiit number of columns on dashboard landing page */
  flex-basis: 49%;
}

/* Ant Design Responsive Date Range Picker */
/* .dashboard-form .ant-picker,.dashboard-form .custom-daterange-picker { */
.dashboard-form .custom-daterange-picker {
  width: 50%;
}


/* .dashboard-form .ant-picker-range {
  width: 600px;
}  */

.ant-picker-panels { 
  flex-direction:row;
} 

.custom-pagination{
  margin-top: 0;
  
}

.footer-section-company{
  display: block; 
}

.footer-section-legal{
  display: block; 
}

}



